<template>
  <div>
    <c-tab
      :dense="true"
      type="tabcard"
      :inlineLabel="true"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :deptData.sync="tab.deptData"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'copliance-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      editable: true,
      tabItems: [],
      splitter: 5,
      tab: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 70
      return numHeight + 'px';
    },
  },  
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.pca.resultItemMst.deptUrl;

      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        sysRevision: this.popupParam.sysRevision, 
        selfInspectionResultId: this.popupParam.selfInspectionResultId
      };
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          this.$_.forEach(_result.data, _item => {
            let idx = this.$_.findIndex(this.tabItems, {name: _item.deptCd})
            if (idx === -1) {
              this.tabItems.push({
                name: _item.deptCd, 
                icon: 'bookmark', label: _item.deptName,
                deptData: {
                  deptCd: _item.deptCd,
                  deptName: _item.deptName,
                  itemList: _item.itemList,
                },
                component: () => import(`${'./resultReport.vue'}`) 
              })
            }
          })
          if (!this.tab) {
            this.tab = _result.data[0].deptCd
          }
        }
      },);
    },
  }
};
</script>
